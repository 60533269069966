import './App.css'

import React, { useEffect, useRef, useState } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import PrivateRoute from './components/Utility/PrivateRoute'
import { useSelector, useDispatch } from 'react-redux'

import { getFormOptions } from './actions/form.actions'

import { useWakeLock } from './hooks/useWakeLock'
import NoSleep from 'nosleep.js'

import Header from './components/Header/Header'
import Sidebar from './components/Sidebar/Sidebar'

import Home from './views/Home/Home'
import HomeBeta from './views/Home/HomeBeta'
import Login from './views/Login/Login'
import Register from './views/Register/Register'
import Recipes from './views/Recipes/Recipes'
import MealPlanList from './views/MealPlans/MealPlanList'
import MealPlanCreate from './views/MealPlans/MealPlanCreateEdit'
import UserRecipes from './views/UserRecipes/UserRecipes'
import UserDashboard from './views/UserDashboard/UserDashboard'
import RecipeCreator from './views/RecipeCreator/RecipeCreator'
import ShoppingListList from './views/ShoppingList/ShoppingListList'
import UserPreferences from './views/UserPreferences/UserPreferences'
import BetaBugReport from './views/BetaBugReport/BetaBugReport'
import ResetForgottenPassword from './views/ResetPassword/ResetForgottenPassword'
import ImportedRecipeDetail from './components/Recipes/ImportedRecipeDetail'
import MealPlanDetail from './views/MealPlans/MealPlanDetail'
import ShoppingListCreateEdit from './views/ShoppingList/ShoppingListCreateEdit'
import ShoppingListDetail from './views/ShoppingList/ShoppingListDetail'
import Notifcations from './views/Notification/Notifications'
import FoodStore from './views/FoodStore/FoodStore'
import GeneralEnquiry from './views/GeneralEnquiry/GeneralEnquiry'
import Recipe from './views/Recipes/Recipe'
import FoodStoreList from './views/FoodStore/FoodStoreList'
import useUserData from './hooks/useUserData'
import Legal from './views/Legal/Legal'
import About from './views/About/About'
import Contact from './views/Contact/Contact'
import Verify from './views/Verify/Verify'
import CustomRecipeDetail from './components/Recipes/CustomRecipeDetail'
import FirstLoginDialog from './components/Dialogs/FirstLoginDialog'


class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    // console.warn(error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      return <h1 className="text-center py-12">Something went wrong.</h1>
    }

    return this.props.children
  }
}

const App = () => {
  const { user: currentUser } = useSelector((state) => state.auth)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const screenLock = useRef(null)
  const [showTutorial, setShowTutorial] = useState(false)

  const noSleep = new NoSleep()

  const { isSupported, released, request, release } = useWakeLock({
    onRequest: () => console.warn('Screen Wake Lock: requested!'),
    onError: () => console.warn('Screen Wake Lock: error'),
    onRelease: () => console.warn('Screen Wake Lock: released!'),
  })

  useUserData(currentUser !== null)

  const handleOnWakeLock = async (enable) => {
    !enable ? release() : request()
    !enable ? noSleep.disable() : noSleep.enable()
  }

  useEffect(() => {
    
    if (currentUser && window.location.pathname === '/') {
      navigate('/user-dashboard')
    }
    
    if (!currentUser && window.location.pathname === '/') {
      navigate('/home')
    }

    if(window.location.search === '?first_login=true') {
      setShowTutorial(true)
    }

    dispatch(getFormOptions())
  }, [])

  const handleOnConfirmTutorial = () => {
    localStorage.setItem('tutorial_enabled', true)
  }
  
  const handleOnCancelTutorial = () => {
    localStorage.setItem('tutorial_enabled', false)
    setShowTutorial(false)
  }

  return (
        <ErrorBoundary>
          <div className="flex flex-col min-h-scree" style={{background: 'rgba(0,0,0,0.6)'}}>
            <Header />

            <div className="flex items-start justify-center min-h-screen w-full">
              { (currentUser) ? <Sidebar onToggleWakeLock={(enable) => { handleOnWakeLock(enable) }} /> : ''  }       
              
              <div className="w-screen flex justify-center items-center py-2">
                  <Routes>
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/legal" element={<Legal />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/register" element={<Register />} />
                    <Route path="/contact" element={<GeneralEnquiry />} />
                    <Route path="/forgot-password" element={<ResetForgottenPassword />} />
                    <Route path="/verify" element={<Verify />} />
                    <Route path="/recipes" element={<Recipes/>} />
                    <Route path="/recipes/:id" element={<Recipe onToggleWakeLock={(enable) => { handleOnWakeLock(enable) }} />} exact />
                    <Route path="/imported-recipes/:id" element={<ImportedRecipeDetail />} exact />
                    <Route path="/home" element={<Home/>} />
                    <Route path="/home-beta" element={<PrivateRoute><HomeBeta/></PrivateRoute>} />
                    <Route path="/user-meal-plans" element={<PrivateRoute><MealPlanList/></PrivateRoute>} />
                    <Route path="/user-meal-plans/:id" element={<PrivateRoute><MealPlanDetail/></PrivateRoute>} />
                    <Route path="/user-meal-plans/create" element={<PrivateRoute><MealPlanCreate/></PrivateRoute>} />
                    <Route path="/user-meal-plans/edit/:id" element={<PrivateRoute><MealPlanCreate/></PrivateRoute>} />
                    <Route path="/user-recipes" element={<PrivateRoute><UserRecipes/></PrivateRoute>} />
                    <Route path="/user-recipes/:id" element={<PrivateRoute><CustomRecipeDetail/></PrivateRoute>} />
                    <Route path="/user-dashboard" element={<PrivateRoute><UserDashboard/></PrivateRoute>} />
                    <Route path="/user-shopping-lists" element={<PrivateRoute><ShoppingListList/></PrivateRoute>} />
                    <Route path="/user-shopping-lists/:id" element={<PrivateRoute><ShoppingListDetail/></PrivateRoute>} />
                    <Route path="/user-shopping-lists/create" element={<PrivateRoute><ShoppingListCreateEdit/></PrivateRoute>} />
                    <Route path="/user-shopping-lists/edit/:id" element={<PrivateRoute><ShoppingListCreateEdit/></PrivateRoute>} />
                    <Route path="/user-notifications" element={<PrivateRoute><Notifcations /></PrivateRoute>} />
                    <Route path="/user-stores/:id" element={<PrivateRoute><FoodStore/></PrivateRoute>} />                    
                    <Route path="/user-stores" element={<PrivateRoute><FoodStoreList/></PrivateRoute>} />                    
                    <Route path="/recipe-creator/*" element={<PrivateRoute><RecipeCreator/></PrivateRoute>} />
                    <Route path="/recipe-creator/:id/*" element={<PrivateRoute><RecipeCreator/></PrivateRoute>} />
                    <Route path="/user-preferences" element={<PrivateRoute><UserPreferences/></PrivateRoute>} />
                    <Route path="/contact-beta" element={<PrivateRoute><BetaBugReport /></PrivateRoute>} />
                  </Routes>
              </div>
            </div>
            {
              showTutorial && <FirstLoginDialog onConfirm={handleOnConfirmTutorial} onCancel={handleOnCancelTutorial} />
            }
          </div>
        </ErrorBoundary>
  )
}

export default App