import React, { useEffect } from 'react'
import OptionDialog from "../Dialogs/OptionDialog"

export default function FoodStoreOptions({
    foodstoreId, 
    title, 
    showDialog,
    isDefault,
    isSharedWithUser = false,
    canEdit = false, 
    onAction = () => {},
    onCancel = () => {}
}) {
    const options = []

    if (!isDefault && !isSharedWithUser) { 
        options.push({ id: 'delete', label: 'delete'})
    }

    if (isSharedWithUser) {
        options.push({ id: 'remove', label: 'remove'})
    }

    if (canEdit && isSharedWithUser) {
        options.push({ id: 'edit', label: 'edit'})
    } 

    if (!isSharedWithUser) { 
        options.push({ id: 'share', label: 'share'})
    }

    if (!isDefault && canEdit && isSharedWithUser) {
        options.push({ id: 'shared_default', label: 'set default'})
    }

    if (!isDefault && !isSharedWithUser) { 
        options.push({ id: 'default', label: 'set default'})
    }
    
    const handleOnOptionSelect = (selection) => {
        onAction(selection, foodstoreId)
    }

    if (!showDialog) {
        return (<></>)
    }

    return (
        <>
            <OptionDialog
                processing={false}
                options={options}
                title={title}
                onSelect={handleOnOptionSelect} 
                showDialog={showDialog}
                onCancel={() => { onCancel() }}
            />
        </>
    )
}