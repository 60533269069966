/** vendor */
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { createWorker } from 'tesseract.js'

/** lib */
import { excludeReceiptLineWords } from '../../constants/OCRExclusions'
import { RegionSelection } from '../../constants/RegionSelection'

const ReportPriceDialog = ({ 
  ingredients = [], 
  isProduct = false,
  showDialog, 
  onConfirm, 
  onCancel 
}) => {
  const [priceReport, setPriceReport] = useState([])
  const [scanResult, setScanResult] = useState('')

  // scan, validate, confirm, complete
  const [currentTab, setCurrentTab] = useState('scan')
  const [processingResults, setProcessingResults] = useState(false)
  const [scanning, setScanning] = useState(false)

  const [reportLocation, setReportLocation] = useState({
    location: '',
    region: '',
    country: 'NZ'
  })

  const availableRegionOptions = [ 
    ...RegionSelection.countries[0].regions[0].cities,
    ...RegionSelection.countries[0].regions[1].cities,
  ]

  const scanReciept = async (newUrl) => {
    setScanning(true)

    const worker = await createWorker('eng')
    const ret = await worker.recognize(newUrl)
    
    await worker.terminate()

    setScanResult(ret)
    setScanning(false)
    setProcessingResults(true)
    setCurrentTab('validate')
  }

  const [reciptImage, setReciptImage] = useState(null)

  const processLines = (lines) => {
    const lineItems = []
    const englishWordRegex = /(?:[a-zA-Z]{3,}[aeiouAEIOU]+[a-zA-Z]+)(?:\s+[a-zA-Z]{3,}[aeiouAEIOU]+[a-zA-Z]+)*/

    for (let i = 0; i < lines.length; i++) {
      const currentLine = lines[i];
      const nextLine1 = lines[i + 1];
      const nextLine2 = lines[i + 2];

      if (excludeReceiptLineWords.some(word => currentLine.text.toLowerCase().includes(word.toLowerCase()))) {
        continue
      }
  
      // Combine lines up to 3 to get the full line item text
      let fullLineItemText = [currentLine.text, nextLine1?.text, nextLine2?.text].join(' ').trim();
  
      // Remove any text after the first occurrence of a price
      const priceRegex = /\$?\d+(\.\d{1,2})?|\£?\d+(\.\d{1,2})?/
      const priceMatch = fullLineItemText.match(priceRegex)

      if (priceMatch) {
        const priceIndex = priceMatch.index;
        if (priceIndex !== undefined && priceIndex !== null) {
          fullLineItemText = fullLineItemText.substring(0, priceIndex + priceMatch[0].length).trim()
        }
      }
      else 
      {
        continue
      }      

      const itemName = fullLineItemText.replace(/[^\w\s]/g, '').trim()

      if(englishWordRegex.test(itemName) && fullLineItemText) {
        const price = priceMatch ? parseFloat(priceMatch[0]) : null

        // Extracting SKU
        const skuRegex = /\d5,}/
        const skuMatch = fullLineItemText.match(skuRegex)
        const sku = skuMatch ? skuMatch[0] : null

        // Extracting currency (supporting $ and pound symbol)
        const currencyRegex = /(\$|£)/
        const currencyMatch = fullLineItemText.match(currencyRegex)
        const currency = currencyMatch ? currencyMatch[0] : null

        lineItems.push({
          name: itemName,
          matchedItem: null,
          quantity: 1,
          unit: 'unit',
          price,
          currency,
          sku,
          checked: false
        })
      }      
    }

    console.warn('lineItems', lineItems)
  }

  const matchItemToIngredient = () => {
    return 'No Matches Found.'
  }

  useEffect(() => {

    if(ingredients?.length > 0 && showDialog) {
      const initalPriceReport = [...ingredients].map((ingredient) => {
        return {
          price: 0.00, // ingredient?.average_price || 0.30,
          currency: 'NZD',
          quantity: ingredient?.default_quantity || ingredient?.quantity,
          measurement: ingredient?.unit || ingredient?.default_unit || 'unit',
          name: ingredient?.name,
          ingredient_product_id: ingredient?.product?.id ?? '',
          ingredient_product_name: ingredient?.product?.name ?? '',
          ingredient_id: ingredient?.ingredient_id,
        }
      })
  
      console.warn(initalPriceReport, ingredients)
      setPriceReport(initalPriceReport)
    }

    return () => {
      if(priceReport) {
        setPriceReport([])
      }
    }

  }, [ingredients, showDialog])

  const handleOnselectUnit = (newValue, ingredientIndex) => {
    const priceReportCopy = [...priceReport]
    
    priceReport[ingredientIndex].measurement = newValue
    setPriceReport(priceReportCopy)
  }

  const handleOnUpdateQuantity = (newValue, ingredientIndex) => {
    const priceReportCopy = [...priceReport]
    priceReportCopy[ingredientIndex].quantity = newValue
    setPriceReport(priceReportCopy)
  }

  const handleOnUpdatePrice = (newValue, ingredientIndex) => {
    const priceReportCopy = [...priceReport]
    priceReportCopy[ingredientIndex].price = newValue
    setPriceReport(priceReportCopy)
  }

  const handleSelectRegion = (e) => {

  }

  const handleNext = (tab) => {
    setCurrentTab(tab)
  }

  const handleCapture = (target) => {
    if (target.files) {
      if (target.files.length !== 0) {
        const file = target.files[0]
        const newUrl = URL.createObjectURL(file)
        scanReciept(newUrl)
      }
    }
  }

  const handleConfirm = () => {
    onConfirm()
  }

  const handleCancel = () => {
    onCancel()
  }

  if(!showDialog) return null

  return (
    <>
      <div className="fixed inset-0 bg-black opacity-30"></div>

      <div className="w-screen px-2 pt-2 mb-3 fixed bottom-0">
        <div className="bg-white p-4 rounded-md shadow-md w-96 max-w-full z-50">
            <h2 className="text-xl font-semibold mb-4">Report Prices</h2>

            <div> 

              <div className="w-full">
                <ul className="flex text-sm font-medium text-center border-b border-black overflow-hidden">
                    <li className="w-full">
                        <a href="#" 
                          onClick={() => { setCurrentTab('scan') }}
                          className={`${currentTab === 'scan' ? 'text-white bg-black active' : ''} inline-block w-full p-2 rounded-t-lg`}>Scan</a>
                    </li>
                    <li className="w-full">
                        <a href="#" 
                          onClick={() => { setCurrentTab('validate') }}
                          className={`${currentTab === 'validate' ? 'text-white bg-black active' : ''} inline-block w-full p-2 rounded-t-lg`}>Validate</a>
                    </li>
                    <li className="w-full">
                        <a href="#"
                          onClick={() => { setCurrentTab('confirm') }}
                          className={`${currentTab === 'confirm' ? 'text-white bg-black active' : ''} inline-block w-full p-2 rounded-t-lg`}>Confirm</a>
                    </li>
                </ul>
              </div>

              <div className="h-[56vh] overflow-y-auto px-2">
                {
                  currentTab === 'scan' &&
                    <div className="flex flex-col h-[54vh]">
                      <div className="flex-grow pt-4">
                        <p className="text-base">Scan your receipt and we'll try to do the work for you.</p>
                        <small className="text-xs">It's always best to exclude personal information in any uploaded receipts. but if you do, dont worry we'll crop them for you. See our <Link to="/legal#privacy"><span className="underline">privacy policy</span></Link> for more details.</small>
                      </div>  

                      <button 
                          onClick={()=> { handleCapture() }}
                          type="button" 
                          className={`rounded font-medium border border-black text-white bg-black text-sm px-5 py-2`}
                          >Scan Reciept</button>

                      <button 
                        onClick={()=> { handleNext('validate') }}
                        type="button" 
                        className={`rounded font-medium border border-black text-sm px-5 py-2 mt-4`}
                      >Skip and enter manually</button>
                    </div>
                }

                {
                  currentTab === 'validate' &&
                    <ul role="list" className="divide-y divide-gray-200 min-w-full">
                      {
                        priceReport?.map((ingredient, ingredientIndex) => { return (
                            <li className="py-3 sm:py-4 min-w-full" key={ingredientIndex}>
                                <div className="flex items-center space-x-4">
                                    <div className="flex-shrink-0 pl-2">
                                        <input 
                                            className={`border w-12 mr-1 border-gray-400 text-black text-center text-xs rounded-t py-[4px] px-[4px]`} 
                                            id="quantity" 
                                            type="number"
                                            min="1"
                                            onChange={(e) => {}}
                                            value={ingredient?.quantity} 
                                        />
                                        <div className="w-12 mb-1">
                                            <div className="w-full overflow-hidden">
                                                <select 
                                                    id="measurement"
                                                    value={ingredient?.measurement}  
                                                    style={{ fontSize: 10 }}
                                                    className="border bg-black border-black w-full text-center text-white leading-none rounded-b block px-1 py-[2px]"
                                                > 
                                                    <option value={ingredient?.measurement}>{ingredient?.measurement}</option>
                                                </select>
                                            </div>                                                                        
                                        </div>
                                        
                                    </div>
          
                                    <div className="flex-1 min-w-0">
                                        <p className="text-md font-medium text-black">
                                            {ingredient?.product?.name || ingredient?.name}
                                        </p>
                                    </div>
                                    <div className="flex">
                                      <div className="p-1">$</div>
                                      <input 
                                        className={`border w-12 mr-1 border-black text-black text-center text-xs rounded appearance-none`} 
                                        id="price" 
                                        type="number"
                                        onChange={(e) => {}}
                                        value={ingredient?.price}
                                        step='0.01' 
                                      />
                                    </div>
                                </div>
                            </li>
                        )}) 
                      }
                    </ul>
                }

                {
                  currentTab === 'confirm' &&
                    <div>
                      <div className="mb-4 items-center">
                        <div className="pt-2 border-t mt-1">
                          <label className="block mb-2 text-xs font-medium text-black" htmlFor="user_avatar">Scan Receipt</label>
                          <input 
                              className="block w-full overflow-hidden text-xs text-black rounded-lg border border-black cursor-pointer" 
                              aria-describedby="report_receipt" 
                              id="report_receipt" 
                              type="file"
                              value={reciptImage}  
                              onChange={(e) => { setReciptImage(e.target.value) }}
                          />
                          <small className="leading-none">Price Reports with proof of purchase are more likely to be approved, (and it saves you entering everything it in by hand)</small>
                        </div>
                      </div>

                      <div className="pt-1">
                        <label className="block py-2 text-xs w-full">Location / Store</label>  
                      </div>

                      <div className="pt-1">
                        <label className="block py-2 text-xs w-full">Region</label>  
                        <select
                          className="border border-black text-black text-sm rounded block w-full p-1"
                          onChange={(e) => { handleSelectRegion(e)} }
                        >
          
                          {
                            availableRegionOptions?.map((option) => {
                              return <option value={option.code} selected={option.code === reportLocation.region}>{option.name}</option>
                            })
                          }
                        </select>
                      </div>
                        
                      <div className="flex justify-between">
                        <div className="pt-1">
                          <label className="block py-2 text-xs w-full">Region</label>  
                          <select
                            disabled                        
                            className="border border-black text-black text-sm rounded block w-full p-1"
                          >
                            <option value="NZ">New Zealand</option>
                          </select>
                        </div>

                        <div className="pt-1">
                          <label className="block py-2 text-xs w-full">Currency</label>  
                        </div>
                      </div> 

                    </div>
                }
              </div>
          </div>
        </div>

        <div className="flex justify-between mt-3">
          <div>
              <button
                  className="px-4 py-2 text-black border bg-white border-black hover:bg-black hover:text-white rounded"
                  onClick={handleCancel}
              >
                  Clear
              </button>

              {/* <button
                  className="px-4 py-2 ml-2 text-black border bg-white border-black hover:bg-black hover:text-white rounded"
                  onClick={handleRemember}
              >
                  Remember
              </button> */}
          </div>

          <button
            className="px-4 py-2 text-white bg-black border border-black hover:bg-black hover:text-white rounded"
            onClick={handleConfirm}
          >
            {currentTab === 'confirm' ? 'Confirm' : 'Next'}
          </button>
        </div>
      </div>
    </>
  )
}

export default ReportPriceDialog
