/** vendor */
import React, { useEffect, useState } from 'react'

/** lib */
import { useToast } from '../../hooks/useToast'

/** components */
import OptionDialog from "../Dialogs/OptionDialog"
import { cancelPublishRequest, createPublishRequest, unpublishUserContent } from '../../services/publishing.service'

export default function CustomRecipeOptions({
    customRecipe,
    publishRequest = null, 
    title, 
    showDialog,
    onAction = () => {}, 
    onCancel = () => {}
}) {
    const toast = useToast()

    const [saving, setSaving] = useState(false)
    const [options, setOptions] = useState([])
    const [publishRequestInstance, setPublishRequestInstance] = useState(null)

    const updateMealplanOptions = () => { 
        const newOptions = []

        if(!publishRequestInstance)
        {
            if(customRecipe?.published) {
                newOptions.push({ id: 'unpublish', label: 'unpublish', disabled: saving })
            } else {
                newOptions.push({ id: 'publish', label: 'publish', disabled: saving })
            }
        }

        if(publishRequestInstance)
        {
            newOptions.push({ id: 'cancel', label: 'cancel', disabled: saving })
        }

        newOptions.push({ id: 'edit', label: 'edit', disabled: saving })
        newOptions.push({ id: 'delete', label: 'delete', disabled: saving })

        setOptions(newOptions)
    }
    useEffect(() => {
        console.warn('publishRequestpublishRequest', publishRequest)
        if(publishRequest) setPublishRequestInstance(publishRequest)
    }, [publishRequest])

    useEffect(() => {
        updateMealplanOptions()

        return () => {
            setOptions([])
            setSaving(false)
        }
    }, [saving, publishRequestInstance])

    /**
     * Handlers
     */
    const handleOnOptionSelect = async (action) => {
        setSaving(true)

        if(action === 'unpublish') {
            const unpublishRequestResult = await unpublishUserContent(customRecipe.user_recipe_id)
            console.warn('unpublishRequestResult', unpublishRequestResult)
            setPublishRequestInstance(null)
            setSaving(false)
            toast.open(`The Recipe was unpublished`)
        } 

        if(action === 'publish') {
            try {
                setSaving(true)

                const publishRequestResult = await createPublishRequest({
                    entity_id: customRecipe.user_recipe_id,
                    entity_type: 'UserRecipe'
                })

                if(publishRequestResult?.error) {
                    toast.open(publishRequestResult?.error)
                    setPublishRequestInstance(publishRequestResult?.request)
                } else {
                    setPublishRequestInstance(publishRequestResult)
                    
                }
                setSaving(false)
            } catch (error) {
                toast.open(`The request to publish was rejected`)
            }


        } 
         
        if(action === 'cancel') {
            try {
                setSaving(true)
                await cancelPublishRequest(publishRequestInstance.id)
                setPublishRequestInstance(null)
                setSaving(false)
                toast.open(`The request to publish was cancelled`)        
            } catch (error) {
                setSaving(false)
                toast.open(`The Request could not be cancelled`) 
            }
        }
        
        if(action === 'delete' || action === 'edit') {
            onAction(action, customRecipe.user_recipe_id)
        } 
    }

    if (!showDialog) {
        return (<></>)
    }

    console.warn('publishRequestInstance', publishRequestInstance)

    return (
        <>
            <OptionDialog
                headline={
                    <>
                        {
                            publishRequestInstance && 
                                <div className="text-center pb-2">
                                    <label>Status: </label>
                                    <strong>{publishRequestInstance?.status}</strong>
                                    <small className="text-xs block py-1">requested on: {new Date(publishRequestInstance?.created_at).toDateString()}</small>
                                    <small className="text-xs block pb-1">last updated: {new Date(publishRequestInstance?.updated_at).toDateString()}</small>
                                </div>
                        }
                    </>
                }
                options={options}
                title={title}
                onSelect={handleOnOptionSelect} 
                showDialog={showDialog}
                onCancel={() => {
                    onCancel()
                }}
            />
        </>
    )
}