import { API_URL, requestHeader } from '../constants/ApiConfiguration'

const has401Error = (status) => {
    if (status === 401) {
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        localStorage.removeItem('saved_recipes')

        window.location.href = '/login'
        window.location.reload()
    } else {
        return false
    }
}

/** Price Reports */
export function fetchPublishRequests() {
    return fetch(API_URL + '/publish-requests/', {
        method: 'GET', 
        headers: requestHeader()
    })
    .then((response) => { 
        if(!has401Error(response?.status)) {
            return response.json()
        }
        return null 
    }) 
}

export function createPublishRequest(payload) {
    return fetch(API_URL + '/publish-requests/', {
        method: 'POST', 
        headers: requestHeader(),
        body: JSON.stringify(payload)
    })
    .then((response) => { 
        if(!has401Error(response?.status)) {
            return response.json()
        }

        return response.json() 
    }) 
    .then((r) => { 
        console.warn(r)
        return r?.data || r
    })
}

export function cancelPublishRequest(id) {
    return fetch(API_URL + `/publish-requests/${id}/cancel`, {
        method: 'GET', 
        headers: requestHeader()
    })
    .then((response) => { 
        if(!has401Error(response?.status)) {
            return response.json()
        }
        return null 
    }) 
    .then((r) => { 
        return r.data 
    })
}

export function unpublishUserContent(payload) {
    return fetch(API_URL + '/publish-requests/unpublish', {
        method: 'PUT', 
        headers: requestHeader(),
        body: JSON.stringify(payload)
    })
    .then((response) => { 
        if(!has401Error(response?.status)) {
            return response.json()
        }
        return null 
    }) 
    .then((r) => { 
        return r.data 
    })
}