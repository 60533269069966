export const RegionSelection = 
{
    "countries": [
      {
        "name": "New Zealand",
        "code": "NZ",
        "regions": [
          {
            "name": "North Island",
            "code": "NI",
            "cities": [
              { "name": "Auckland", "code": "NZ-AKL" },
              { "name": "Wellington", "code": "NZ-WLG" },
              { "name": "Hamilton", "code": "NZ-HAM" },
              { "name": "Tauranga", "code": "NZ-TRG" },
              { "name": "Napier", "code": "NZ-NPE" }
            ]
          },
          {
            "name": "South Island",
            "code": "SI",
            "cities": [
              { "name": "Christchurch", "code": "NZ-CHC" },
              { "name": "Queenstown", "code": "NZ-ZQN" },
              { "name": "Dunedin", "code": "NZ-DUD" },
              { "name": "Nelson", "code": "NZ-NSN" },
              { "name": "Invercargill", "code": "NZ-IVC" }
            ]
          }
        ]
      },
      {
        "name": "Australia",
        "code": "AU",
        "regions": [
          {
            "name": "New South Wales",
            "code": "NSW",
            "cities": [
              { "name": "Sydney", "code": "AU-SYD" },
              { "name": "Newcastle", "code": "AU-NTL" },
              { "name": "Wollongong", "code": "AU-WOL" },
              { "name": "Coffs Harbour", "code": "AU-CFS" }
            ]
          },
          {
            "name": "Victoria",
            "code": "VIC",
            "cities": [
              { "name": "Melbourne", "code": "AU-MEL" },
              { "name": "Geelong", "code": "AU-GEX" },
              { "name": "Ballarat", "code": "AU-BAT" },
              { "name": "Bendigo", "code": "AU-BGO" }
            ]
          },
          {
            "name": "Queensland",
            "code": "QLD",
            "cities": [
              { "name": "Brisbane", "code": "AU-BNE" },
              { "name": "Gold Coast", "code": "AU-OOL" },
              { "name": "Cairns", "code": "AU-CNS" },
              { "name": "Townsville", "code": "AU-TSV" }
            ]
          },
          {
            "name": "Western Australia",
            "code": "WA",
            "cities": [
              { "name": "Perth", "code": "AU-PER" },
              { "name": "Fremantle", "code": "AU-FRE" },
              { "name": "Broome", "code": "AU-BME" },
              { "name": "Bunbury", "code": "AU-BUY" }
            ]
          },
          {
            "name": "South Australia",
            "code": "SA",
            "cities": [
              { "name": "Adelaide", "code": "AU-ADL" },
              { "name": "Mount Gambier", "code": "AU-MGB" },
              { "name": "Port Lincoln", "code": "AU-PLO" }
            ]
          }
        ]
      },
      {
        "name": "United Kingdom",
        "code": "UK",
        "regions": [
          {
            "name": "England",
            "code": "ENG",
            "cities": [
              { "name": "London", "code": "UK-LON" },
              { "name": "Manchester", "code": "UK-MAN" },
              { "name": "Birmingham", "code": "UK-BHX" },
              { "name": "Leeds", "code": "UK-LDS" },
              { "name": "Bristol", "code": "UK-BRS" }
            ]
          },
          {
            "name": "Scotland",
            "code": "SCO",
            "cities": [
              { "name": "Edinburgh", "code": "UK-EDI" },
              { "name": "Glasgow", "code": "UK-GLA" },
              { "name": "Aberdeen", "code": "UK-ABZ" },
              { "name": "Dundee", "code": "UK-DND" }
            ]
          },
          {
            "name": "Wales",
            "code": "WLS",
            "cities": [
              { "name": "Cardiff", "code": "UK-CWL" },
              { "name": "Swansea", "code": "UK-SWS" },
              { "name": "Newport", "code": "UK-NWP" },
              { "name": "Wrexham", "code": "UK-WRE" }
            ]
          },
          {
            "name": "Northern Ireland",
            "code": "NIR",
            "cities": [
              { "name": "Belfast", "code": "UK-BFS" },
              { "name": "Derry", "code": "UK-DRY" },
              { "name": "Lisburn", "code": "UK-LSB" },
              { "name": "Newry", "code": "UK-NRY" }
            ]
          }
        ]
      }
    ]
  }
  
  