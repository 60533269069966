import { API_URL, requestHeader } from '../constants/ApiConfiguration'

export function updateSharedWith(payload) {
    return fetch(API_URL + '/share', {
        method: 'PUT', 
        headers: requestHeader(),
        body: JSON.stringify(payload)
    }).then(response => response.json()) 
}

export function fetchSharedWithMe() {
    return fetch(API_URL + '/share', {
        method: 'GET', 
        headers: requestHeader()
    }).then(response => response.json()) 
}

// export function removeSharedList(payload) {
//     return fetch(API_URL + '/share/remove', {
//         method: 'PUT', 
//         headers: requestHeader(),
//         body: JSON.stringify(payload)
//     }).then(response => response.json()) 
// }

export function removeSelfFromSharedList(payload) {
    return fetch(API_URL + '/share/remove', {
        method: 'PUT', 
        headers: requestHeader(),
        body: JSON.stringify(payload)
    }).then(response => response.json()) 
}

export function setDefaultSharedStore(payload) {
    return fetch(API_URL + '/share/default', {
        method: 'PUT', 
        headers: requestHeader(),
        body: JSON.stringify(payload)
    }).then(response => response.json()) 
}



