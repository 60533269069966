/** vendor */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

/** lib */
import Loading from '../UI/Loading'

/** state */
import { getUserRecipes } from '../../actions/user.actions'
import CustomRecipeOptions from './CustomRecipeOptions'
import { fetchPublishRequests } from '../../services/publishing.service'

/** components */

export default function CustomRecipeList() {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const recipes = useSelector(state => state?.user_data?.user_recipes)
    const [publishRequests, setPublishRequests] = useState([])

    const [loading, setLoading] = useState(false)

    const [recipesWithRequests, setRecipesWithRequests] = useState(null)


    const [selectedRecipe, setSelectedRecipe] = useState(null)
    const [showUserRecipeOptions, setShowUserRecipeOptions] = useState(false)

    /**
     * Hooks
     */
    useEffect(() => {
        setLoading(true)

        dispatch(getUserRecipes()).then(() => {
            fetchPublishRequests().then((publishRequests) => {
                setPublishRequests(publishRequests)
                
                const mapRequestsToRecipes = [...recipes].map(r => {
                    const request = publishRequests.find(req => r.user_recipe_id === req?.entity_id && req?.entity_type === 'UserRecipe') || { request: null }
                    
                    return {
                        ...r,
                        request
                    }
                }) 

                setRecipesWithRequests(mapRequestsToRecipes)
                setLoading(false)
            })
            setLoading(false)    
        }).catch(() => {
            setLoading(false)
        })
    }, [])

    /**
     * Handlers
     */
    const handleOnSelectRecipe = (recipe) => {
        console.warn('handleOnSelectRecipe', recipe)

        setSelectedRecipe(recipe)
        setShowUserRecipeOptions(true)
    }

    const handleOnAction = (action, recipeId, requestId = null) => {
        if(action === 'edit') {
            // warn this will unpublish the recipe and new request will have to be submitted
            // unpublish the recipe on confirm
            navigate(`/user-recipes/${recipeId}`)
            return
        }

        if(action === 'delete') {
            if(requestId) {
                // delete the publish request
            }
        }
        
        setLoading(true)

        dispatch(getUserRecipes()).then(() => {
            fetchPublishRequests().then((publishRequests) => {
                setPublishRequests(publishRequests)
                setLoading(false)
            })    
        }).catch(() => {
            setLoading(false)
        })
    }

    const handleOnCancel = () => {
        setSelectedRecipe(null)
        setShowUserRecipeOptions(false)
    }

    if(loading) {
        return (<div className="h-[60vh] relative pt-40"><Loading /></div>)
    }

    return (
        <div className="w-full h-[70vh]">
            <section className="antialiased text-black px-1">
                <div className="p-3 w-full lg:w-2/3">
                    <div className="overflow-x-auto">
                        { 
                            recipesWithRequests &&
                                <>
                                    {
                                        recipesWithRequests.map((recipe, index) => (
                                            <div key={index} className="w-full p-1 px-2 mb-4">
                                                <div className="flex-1 border-b border-black cursor-pointer" 
                                                    onClick={() => { handleOnSelectRecipe(recipe) }}
                                                >    
                                                    <div>                                                
                                                        <div className="font-light pb-2 flex justify-between">
                                                            <small className="text-left text-lg block">{ recipe.name }</small>
                                                            <small className="text-right text-xs block">Serves: {recipe.serves}</small>
                                                        </div>
                                                        <div className="flex justify-between items-end">
                                                            <div className="text-left flex-wrap text-xs leading-tight flex-grow py-1">
                                                                {recipe.description}
                                                            </div>
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mb-1 flex-shrink-0">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
                                                            </svg>
                                                        </div>

                                                        
                                                    </div>               
                                                </div>
                                            </div>
                                        ))
                                    }
                                </>        
                        }
                    </div>
                </div>
            </section>
 
            <CustomRecipeOptions 
                customRecipe={selectedRecipe}
                publishRequest={selectedRecipe?.request} 
                title={selectedRecipe?.name}
                showDialog={showUserRecipeOptions}
                onAction={handleOnAction}
                onCancel={handleOnCancel}
            />
        </div>
    )

}