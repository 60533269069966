import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ManageFoodStoreItemsDialog from '../Dialogs/ManageFoodStoreItemsDialog'

export default function FoodStoreActionBar({ foodstore, itemsToManage = [] }) {

    const [expired, setExpired] = useState([])
    const [expiring, setExpiring] = useState([])
    const [selected, setSelected] = useState(null)
    const [showDialog, setShowDialog] = useState(false)

    // const setInMealplanCount = () => {
    //     // const activeMeaplans = [...mealplans, ...sharedWithMeMealplans].filter((plan) => {
    //     //     const today = new Date()
    //     //     const start = plan?.start_date ? new Date(plan?.start_date) : null
    //     //     const end = plan?.end_date ? new Date(plan?.end_date) : null

    //     //     return (today >= start && today <= end && plan?.recipes?.length > 0) 
    //     // })



    //     // console.warn(activeMeaplans)

    //     // get recieps ffrom mealplans

    //     // get ingredients from recipes needs a request

    //     // add quantities together for same ingredients

    //     // check they are on the list

    //     // report in plan

    //     // if items in plan
    // }

    const setCount = () => {
        let exp = []
        let expr = []

        for(const item of itemsToManage) {
            const dateadded = new Date(item?.date_added)
            const days = item?.estimated_expiry_days || 14
            
            const expdate = new Date()
            const expsoon = new Date()
            const today = new Date()

            expdate.setDate(dateadded.getDate() + days)
            expsoon.setDate(dateadded.getDate() + (days - 3))

            if(expdate >= today) {
                exp.push(item)
            } else if(expsoon >= today) {
                expr.push(item)
            } 
        }

        setExpired(exp)
        setExpiring(expr)
    }
    
    useEffect(() => {
        if(itemsToManage?.length > 0) {
            setCount()
        }
    }, [itemsToManage])

    const handleOnAction = (action) => {
        setSelected(null)
        setShowDialog(false)
    }

    const handleShowDialog = (type) => {
        if(type === 'mealplan') {
            setSelected([])
        }

        if(type === 'expiring') {
            setSelected(expiring)
        }

        if(type === 'expired') {
            setSelected(expired)
        }

        setShowDialog(true)
    }

    if(itemsToManage?.length < 1) return <></>

    return (
        <div className="w-full pb-2 flex justify-between px-2">
            <div className="w-1/3 p-1">
                <button
                    onClick={() => { handleShowDialog('mealplan') }}
                    className={`
                        ${[].length > 0 ? 'text-white bg-black' : 'text-black bg-white'}  
                        p-1 rounded font-medium border border-black text-xs w-full block
                    `}>In plan (0)</button>
            </div>

            <div className="w-1/3 p-1">
                <button
                    onClick={() => { handleShowDialog('expiring') }}
                    className={`
                        ${expiring?.length > 0 ? 'text-white bg-black' : 'text-black bg-white'}  
                        p-1 rounded font-medium border border-black text-xs w-full block
                    `}
                >Expiring ({expiring?.length})</button>
            </div>

            <div className="w-1/3 p-1">
                <button
                    onClick={() => { handleShowDialog('expired') }}
                    className={`
                        ${expired?.length > 0 ? 'text-white bg-black' : 'text-black bg-white'}
                        text-white p-1 bg-black rounded font-medium border border-black text-xs w-full block
                    `}
                >Expired ({expired?.length})</button>
            </div>

            {
                showDialog &&
                    <ManageFoodStoreItemsDialog
                        foodstore={foodstore} 
                        storeItems={selected} 
                        canEditStore={true} 
                        showDialog={showDialog}
                        onAction={handleOnAction}
                    />
            }
        </div>
    )
}