/** vendor */
import React, { useState, useEffect, } from 'react'
import { PlayIcon, PauseIcon } from '@heroicons/react/solid'

/** lib */
import { IMG_URL, PLACEHOLDER_IMG } from '../../constants/ApiConfiguration'
import { ChevronUpIcon, ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon, ClockIcon } from '@heroicons/react/outline'
import Timer from '../UI/Timer'


export default function RecipeCookMode({ recipe, onToggleWakeLock }) {
    const [currentStep, setCurrentStep] = useState(0)
    const [timers, setTimers] = useState([])
    const [showIngredients, setShowIngredients] = useState(false)
    
    const displayFormat = (value) => { 
        return value.split(':').map(i => i.length < 2 ? '0' + i : i).join(':')
    } 

    useEffect(() => {
        if(recipe?.current_recipe?.recipe_steps) {
            const initalTimers = []
            
            recipe?.current_recipe?.recipe_steps.forEach((step, index) => {
                if(step?.timer) {
                    initalTimers.push({
                        start_time: step.timer,
                        time_remaining: step.timer,
                        paused: true,
                        step_number: index
                    })
                } else {
                    initalTimers.push({
                        start_time: null,
                        time_remaining: null,
                        paused: true,
                        step_number: index
                    })
                }
            })

            setTimers(initalTimers)

            return () => {
                onToggleWakeLock(false)
            }
        }
    }, [recipe])

    const handleOnClickTimer = (enabled) => {
        onToggleWakeLock(enabled)
    }

    return (
        <div className="w-full flex justify-center relative">
            <div className="flex flex-col w-full">
                <div className="w-full">
                    <div className="flex w-full pb-4 overflow-y-scroll">
                        <div className="flex" style={{ width: `calc(25% * ${timers?.length || 0})` }}>
                            { 
                                recipe.current_recipe.recipe_steps
                                    .sort((a, b) => a.step_order > b.step_order ? 1 : -1)
                                    .map((s, i) => 
                                        <div 
                                            className={`${i === currentStep ? 'border border-black rounded-sm' : ''} p-1 text-black text-xs text-center w-[25vw]`}
                                            key={i}
                                        >
                                            <button 
                                                onClick={ () => { setCurrentStep(i) }}
                                                className="block text-center w-full"
                                            >{i+1}</button>
                                            <button 
                                                onClick={ () => { setCurrentStep(i) }}
                                                className="block text-center w-full"
                                            >
                                                {
                                                    timers[i]?.start_time ? 
                                                        <Timer 
                                                            onClickTimer={handleOnClickTimer} 
                                                            timerObj={timers[i]} 
                                                            restartTimer={false} 
                                                            pauseTimer={true} 
                                                        /> : <></>
                                                }
                                            </button>
                                        </div>
                                )
                            }
                        </div>
                    </div>

                    <div className="mt-2 border-t border-black">
                        { 
                            recipe.current_recipe.recipe_steps
                                .sort((a, b) => a.step_order > b.step_order ? 1 : -1)
                                .map((step, i) => {
                                    const stepImage = (step.image) ? `${IMG_URL}/${step.image}` : PLACEHOLDER_IMG
                                    const ingredientList = [...recipe.current_recipe.recipe_ingredients]

                                    return <div key={i}>
                                        {
                                            i === currentStep ? 
                                                <div className="mb-2 pb-2" key={i} >
                                                    <label className="lowercase text-center py-2 block w-full text-black">{step.name}</label>
                                                
                                                    <div className="flex py-2">
                                                        <p className="p-4 whitespace-pre-wrap">
                                                            {step.description}
                                                        </p>
                                                    </div>
                                            
                                                    <div className="w-full bg-white">

                                                        <div className="border border-black p-1">
                                                            <div className="w-full flex justify-between">
                                                                <label className="text-black text-xs text-center p-2">Ingredients in this step</label>
                                                                <button onClick={() => { setShowIngredients(!showIngredients) }} >
                                                                    { showIngredients ? <ChevronUpIcon  className="w-6 h-6"/> : <ChevronDownIcon className="w-6 h-6" />  }
                                                                </button>            
                                                            </div>
                                                            {
                                                                showIngredients ? 
                                                                    <div>                                                            
                                                                        { 
                                                                            ingredientList.filter(i => step.ingredients.includes(i.recipe_ingredient_id)).map((i) => 
                                                                                <div 
                                                                                    key={i.ingredient_id} 
                                                                                    className="text-black text-xs p-1 m-1 border-b "
                                                                                >
                                                                                    {i.quantity} {i.measurement} - {i.treatment} {i.ingredient.name}
                                                                                </div> 
                                                                            )
                                                                        }
                                                                    </div> : <></>
                                                            }
                                                        </div>

                                                        


                                                    </div>                               
                                                </div> : <></> 
                                        }
                                    </div>
                                }
                            )
                        }
                    </div>
                </div>
            </div>
            <div className="fixed bottom-0 left-0 w-screen py-4 flex justify-between px-2">
                <button
                    disabled={currentStep < 1} 
                    onClick={()=> { if (currentStep !== 0) setCurrentStep(currentStep - 1) }} 
                    className={`${currentStep < 1 ? 'opacity-60' : ''} text-black bg-white rounded font-medium border border-black text-sm px-5 py-2`}
                >Back</button>
                
                <button
                    disabled={recipe?.current_recipe?.recipe_steps?.length === currentStep}
                    onClick={()=> { if (currentStep < recipe?.current_recipe?.recipe_steps?.length - 1) { setCurrentStep(currentStep + 1) }}}
                    className={`${recipe?.current_recipe?.recipe_steps?.length - 1 === currentStep ? 'opacity-60' : ''} text-white bg-black rounded font-medium border border-black text-sm px-5 py-2`}
                >
                    Next
                </button>
            </div>
        </div>  
    )
}